<template>
  <div>
    <div v-show="visible" :style="{left:left+'px',top:top+'px'}" class="platform-select" ref="panelPlatform">
      <div style="margin-left: 0">
        <el-tooltip effect="dark" content="拖动" placement="top-start">
          <span class="drag-handle" @mousedown="mouseDownHandle($event)"
                @mouseup="mouseUpHandle($event)"><i
              class="el-icon-rank"></i></span>
        </el-tooltip>
        <span class="title">{{ currentRow.nickname }}-考核平台</span>
        <div class="check-all">
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
                       class="checkbox-color">全选
          </el-checkbox>
        </div>

        <el-checkbox-group v-model="showPlatforms" @change="handleCheckedChange">
          <ul>
            <li v-for="(platform,index) in platforms" :key="index">
              <el-checkbox :label="platform.code" class="checkbox-color">
                {{ platform.name }}
              </el-checkbox>
            </li>
          </ul>
        </el-checkbox-group>
        <div style="text-align: center;width: 100%;margin-top: 10px">
          <el-button type="primary" size="mini" @click="savePlatforms">保存</el-button>
          <el-button size="mini" @click="closeMenu">关闭</el-button>
        </div>
        <!--        <div style="margin-top: 20px">-->
        <!--          <span style="margin-inline-start: 5px;">PS：从有账号的平台中选择要考核平台</span>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PerformancePlatformsSelect",
  props: {
    performanceType: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      moveDataelse: {
        x: null,
        y: null
      },
      //start:鼠标右键菜单
      visible: false,
      top: 0,
      left: 0,
      //end:鼠标右键菜单
      currentRow: {},
      currentColumn: {},
      platforms: [],//红人所有平台
      allPlatformCodes: [],//红人所有平台
      showPlatforms: [],//要显示的平台
      checkAll: false,
      isIndeterminate: false
    }
  },
  created() {

  },
  methods: {
    async init(top, left, currentRow) {
      this.currentRow = currentRow
      this.top = top - 10
      this.left = left
      this.visible = true
      let { checked_codes, platforms } = await this.$api.getPerformancePlatforms({
        artist_id: currentRow.artist_id,
        type: this.performanceType
      })
      this.showPlatforms = checked_codes
      this.platforms = platforms
      this.allPlatformCodes = []
      this.platforms.forEach((item) => {
        this.allPlatformCodes.push(item.code)
      })
      this.isIndeterminate = this.showPlatforms.length > 0 && this.showPlatforms.length < this.allPlatformCodes.length;
      this.checkAll = this.showPlatforms.length === this.allPlatformCodes.length
    },
    async savePlatforms() {
      //savePlatforms
      let info = await this.$api.setPerformancePlatforms({
        platforms: this.showPlatforms,
        artist_id: this.currentRow.artist_id,
        type: this.performanceType
      })
      if (info === 'ok') {
        // this.$notify.success('保存成功')
        this.closeMenu()
        this.$emit('savedEvent')
      }
    },
    handleCheckAllChange(val) {
      this.showPlatforms = val ? this.allPlatformCodes : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allPlatformCodes.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allPlatformCodes.length;
    },
    closeMenu() {
      this.visible = false;
    },
    mouseDownHandle(event) {
      this.$nextTick(() => {
        this.moveDataelse.x = event.pageX - this.$refs.panelPlatform.offsetLeft
        this.moveDataelse.y = event.pageY - this.$refs.panelPlatform.offsetTop
        event.currentTarget.style.cursor = 'move'
        window.onmousemove = this.mouseMoveHandle
      })

    },
    mouseMoveHandle(event) {
      this.$nextTick(() => {
        let moveLeft = event.pageX - this.moveDataelse.x + 'px'
        let moveTop = event.pageY - this.moveDataelse.y + 'px'
        this.$refs.panelPlatform.style.left = moveLeft
        this.$refs.panelPlatform.style.top = moveTop
      })

    },
    mouseUpHandle(event) {
      window.onmousemove = null
      event.currentTarget.style.cursor = 'move'
    }
  }
}
</script>

<style lang="scss" scoped>
.platform-select {
  //background-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(48, 49, 52, 0.7);
  border: solid 1px #f2f2f2;
  margin: 0;
  //background: #fff;
  z-index: 99999;
  position: absolute;
  list-style-type: none;
  padding: 15px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  //color: #333;
  color: #f2f2f2 !important;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    border-top: solid 1px #f2f2f2;
  }

  li {
    margin: 5px 0 2px 0;
    padding: 7px 10px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 49, 118, 0.7);
      //background: rgba(48, 49, 52, 0.7);
    }
  }

  .title {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding-inline-start: 12px;
    padding-inline-end: 10px;
    margin-bottom: 20px;
    margin-inline-start: 1px;
    margin-inline-end: 10px;
  }

  .drag-handle {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }

}

.checkbox-color {
  color: #f2f2f2;
}

.check-all {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  border-top: solid 1px #f2f2f2;
  margin: 5px 0 2px 0;
  padding: 7px 30px;
  cursor: pointer;

}
</style>