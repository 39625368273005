<template>
  <div class="performance-objectives">
    <el-row class="table-search">
      <el-col :span="18">
        <el-form :model="searchCondition" :inline="true" size="small">
          <el-form-item>
            <DeptCascaderScope :type="`performance_data`" ref="refDeptCascaderScope" :show-first-group="true"
                               @selectedDept="selectDept" @firstGroup="currentGroup"/>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                v-model="searchCondition.year"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年度" style="width: 140px" @change="changeYear">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-popover
                placement="right"
                title=""
                width="500"
                trigger="click"
            >
              <el-button size="small" type="default" slot="reference">
                选择季度<span v-if="searchCondition.quarter">: 第 {{ searchCondition.quarter }} 季度</span>
              </el-button>
              <el-row>
                <el-col :span="24">
                  <el-radio-group v-model="searchCondition.quarter" @change="changeQuarter">
                    <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item label="数据版本：">
            <el-radio-group v-model="searchCondition.release_status" @change="changeRelease" size="mini">
              <el-radio-button label="Y">已发布</el-radio-button>
              <el-radio-button label="N" v-if="userPermissions.indexOf('performance_quotient_edit')>-1">编辑中
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6" style="text-align: right;">
        <el-link type="primary" :disabled="loadingExportAll" @click="exportDataAll"
                 v-if="userPermissions.indexOf('performance_export_all') !== -1"
                 class="export-excel">
          <i :class="`${loadingExportAll?'el-icon-loading':'el-icon-download'}`"></i>
          导出全部组
        </el-link>
        <export-link ref="refExportLink" :title="`仅导出当前选择组：${searchCondition.group_name}`" :lint-title="`导出表格`"
                     :can-export="userPermissions.indexOf('performance_export') !== -1"
                     @export="exportData"/>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-tabs v-model="activeName" class="performance-tab" tab-position="left" @tab-click="handleTabClick">
          <el-tab-pane label="涨粉绩效目标" name="trend" v-if="userPermissions.indexOf('performance_trend_page') !== -1">
            <PerformanceTrend ref="refPerformanceTrend" :search-condition="realCondition" :show-bonus="userPermissions.indexOf('performance_bonus_show') !== -1"/>
          </el-tab-pane>
          <el-tab-pane label="营收绩效目标" name="revenue" v-if="userPermissions.indexOf('performance_revenue_page') !== -1">
            <PerformanceRevenue ref="refPerformanceRevenue" :search-condition="realCondition" :show-bonus="userPermissions.indexOf('performance_bonus_show') !== -1"/>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import PerformanceTrend from "@/pages/performance/components/PerformanceTrend";
import PerformanceRevenue from "@/pages/performance/components/PerformanceRevenue";
import ExportLink from "@/components/export/ExportLink";

export default {
  name: "PerformanceObjectives",
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  components: {
    PerformanceTrend,
    PerformanceRevenue,
    ExportLink
  },
  data() {
    return {
      activeName: 'trend',
      searchCondition: {year: null, group_id: null, group_name: '', months: [], quarter: 0, release_status: 'Y'},
      realCondition: {},
      year: null,
      quarters: [
        {label: '未选季度', value: 0},
        {label: '第1季度', value: 1},
        {label: '第2季度', value: 2},
        {label: '第3季度', value: 3},
        {label: '第4季度', value: 4},
      ],
      conditionStr: '',
      groupInfo: {},
      loadingExportAll: false

    }
  },
  created() {
    this.searchCondition.year = (new Date()).getFullYear() + ''
    // 获取当前季度：
    this.searchCondition.quarter = this.$utils.getCurrentQuarter()
    this.changeQuarter()
  },
  mounted() {
    // console.log(this.userPermissions)
    if (this.userPermissions) {
      this.searchCondition.release_status = this.userPermissions.indexOf('performance_quotient_edit') > -1 ? 'N' : 'Y'
    }
  },
  methods: {
    async currentGroup(group) {
      if (group && group.dpt_id) {
        await this.setGroupInfo(group.dpt_id, group.name)
      }
    },
    async setGroupInfo(group_id, group_name) {
      // this.$refs['refDeptCascaderScope'].initVal(group_id)
      this.searchCondition.group_id = group_id;
      this.searchCondition.group_name = group_name;
      this.showTableData()
    },
    /**
     * 部门选择：val-label
     * @param valArr
     * @param labelArr
     * @returns {Promise<void>}
     */
    async selectDept(valArr, labelArr) {
      let group_id = null;
      let group_name = null;
      if (valArr && valArr.length > 1) {
        let len = valArr.length;
        group_id = valArr[len - 1];
        group_name = labelArr[len - 1];
      }
      await this.setGroupInfo(group_id, group_name)
    },


    changeYear() {
      // this.searchCondition.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];//月份的集合
      this.showTableData()
      // this.initData()
    },
    changeQuarter() {
      //计算季度对应的月份
      let quarter = this.searchCondition.quarter
      this.searchCondition.months = [quarter * 3 - 2, quarter * 3 - 1, quarter * 3];//月份的集合
      this.showTableData()
    },
    changeRelease() {
      this.showTableData()
    },
    showTableData() {
      let condition = this.handleSearchCondition();
      if (condition.year && condition.group_id && condition.months) {
        this.realCondition = condition
      }
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = '';
      let conditionArr = [];
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.release_status) {
        condition.release_status = this.searchCondition.release_status
      } else {
        condition.release_status = 'Y'
      }

      if (this.searchCondition.group_name) {
        condition.group_name = this.searchCondition.group_name
        conditionArr.push('【' + condition.group_name + '】');
      }

      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
        conditionArr.push('【' + condition.year + '年】');
      }

      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
        let condTxt = `【第${condition.quarter}季度】`
        if (this.searchCondition.months && this.searchCondition.months.length > 0) {
          condition.months = this.searchCondition.months
          condTxt = `【第${condition.quarter}季度(${condition.months.join('月、')}月)】`
        }
        conditionArr.push(condTxt);
      }
      if (conditionArr.length > 0)
        this.conditionStr = '筛选条件：' + conditionArr.join('/');

      return condition
    },
    //tab切换事件
    handleTabClick() {
      // this.showTableData()
    },
    async exportData() {
      try {
        let response = null
        let searchCondition = this.handleSearchCondition()
        let name = null
        switch (this.activeName) {
          case "trend":
            response = await this.$api.exportPerformanceBill(searchCondition)
            name = `涨粉绩效-${searchCondition.group_name}[${searchCondition.year}][第${searchCondition.quarter}季度]`
            break;
          case 'revenue':
            response = await this.$api.exportPerformanceRevenueBill(searchCondition)
            name = `营收绩效-${searchCondition.group_name}[${searchCondition.year}][第${searchCondition.quarter}季度]`
            break;
          default:
            return
        }

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    async exportDataAll() {
      try {
        this.loadingExportAll = true
        let searchCondition = this.handleSearchCondition()

        switch (this.activeName) {
          case "trend":
            await this.$api.exportPerformanceBillAll(searchCondition)

            break;
          case 'revenue':
            await this.$api.exportPerformanceRevenueBillAll(searchCondition)
            break;
          default:
            return
        }
        setTimeout(() => {
          this.loadingExportAll = false
          this.$notify({
            title: '导出文件生成中',
            dangerouslyUseHTMLString: true,
            message: '<strong>点击这里 <a href="/download_file" target="_blank">下载</a> 生成的文件</strong>',
            type: 'success',
            duration: 5000
          })
        }, 2000)


      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },

  }
}
</script>

<style scoped>

</style>
<style>

</style>
