<template>
  <div>
    <el-tooltip effect="dark" content="上季月营收均值（上季度已发布并核算完成的值为准）" placement="top">
      <el-button type="text" @click="showQuarterReal">（{{ valFormat(average_value) }}）</el-button>
    </el-tooltip>
    <el-dialog
        title="上季度营收均值"
        :visible.sync="dialogVisible"
        width="90%"
    >
      <RevenueHistoryPanel :data-list="dataList" :quarterly-months="quarterlyMonths"/>
    </el-dialog>
  </div>

</template>

<script>
import RevenueHistoryPanel from "@/pages/performance/components/RevenueHistoryPanel";

export default {
  name: "LastQuarterRevenueAverage",
  components: {RevenueHistoryPanel},
  props: {
    searchCondition: {
      type: Object
    }
  },
  data() {
    return {
      average_value: null,
      dialogVisible: false,
      dataList: []
    }
  },
  computed: {
    quarterlyMonths() {
      let quarter = this.searchCondition.quarter === 1 ? 4 : this.searchCondition.quarter - 1
      return [quarter * 3 - 2, quarter * 3 - 1, quarter * 3];
    }
  },
  watch: {
    searchCondition:{
      deep:true,
      immediate:true,
      handler(){
        this.getQuarterAgv()
      }
    }
  },
  methods: {
    valFormat(value) {
      return this.$utils.numberFormat(value, 0, '.', ',', 'round')
    },
    async showQuarterReal() {
      await this.getList()
      this.dialogVisible = true
    },
    async getList() {
      let searchCondition = this.handleSearchCondition()
      let {list} = await this.$api.getHistoryListOfPerformanceRevenue(searchCondition)
      this.dataList = list
    },
    async getQuarterAgv() {
      //quarter_agv
      let searchCondition = this.handleSearchCondition()
      let {real_agv} = await this.$api.getQuarterAgvOfPerformanceRevenue(searchCondition)
      this.average_value = real_agv
    },
    handleSearchCondition() {
      let params = {};
      if (this.searchCondition.year && this.searchCondition.quarter && this.searchCondition.group_id) {
        params.group_id = this.searchCondition.group_id
        params.quarter = this.searchCondition.quarter === 1 ? 4 : this.searchCondition.quarter - 1
        params.year = this.searchCondition.quarter === 1 ? this.searchCondition.year - 1 : this.searchCondition.year
      }

      return params;
    }
  },
  mounted() {
    // this.getQuarterAgv()
  }
}
</script>

<style scoped>

</style>