<template>
  <div class="tips">

    <el-tooltip effect="dark" :content="`${isShow?'仅展示前'+this.limit+'条说明':'查看更多'}`" placement="top-start">
      <el-link class="title" @click="showList=!showList">
        <span>
          <i class="el-icon-info" style="font-size: 1.2rem;color: #ff3176"></i>
          {{ title }}
        </span>
      </el-link>
    </el-tooltip>
    <ol v-if="isShow">
      <li v-for="(msg,index) in data" :key="index" v-html="msg"></li>
    </ol>
    <ol v-else>
      <li v-for="(msg,index) in dataTop" :key="index" v-html="msg"></li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "Tips",
  data() {
    return {
      showList: false,
      limit: 5
    }
  },
  props: {
    data: {
      type: [Array, Object],
      default() {
        return {}
      }
    },
    title: {
      type: String,
      default() {
        return '备注：'
      }
    },
    isShowAll: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    dataTop() {
      return this.data && this.data.length > this.limit ? this.data.slice(0, this.limit) : this.data
    },
    isShow() {
      let data = false
      if (!this.isShowAll)
        data = this.showList
      else data = this.isShowAll
      return data
    }
  },
}
</script>

<style scoped>
.title {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
}

.tips {
  margin-top: 10px;
}

.tips li {
  /*font-weight: lighter;*/
  font-size: 0.9em;
  /*font-style: italic;*/
  color: #909399;
  margin-bottom: 5px;
}

</style>
