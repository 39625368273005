<template>
  <div>
    <div v-show="visible" :style="{left:left+'px',top:top+'px'}" class="performance-revenue" ref="panelRevenue">
      <div style="margin-left: 0;padding: 10px">
        <el-row>
          <el-col :span="2">
            <el-tooltip effect="dark" content="拖动" placement="top-start">
                <span class="drag-handle" @mousedown="mouseDownHandle($event)"
                      @mouseup="mouseUpHandle($event)"><i
                    class="el-icon-rank"></i></span>
            </el-tooltip>
          </el-col>
          <el-col :span="20" style="text-align: center">
            <el-link class="title" @click="initList">{{ loadingText }}</el-link>
          </el-col>
          <el-col :span="2" style="text-align: right">
            <el-tooltip effect="dark" content="关闭" placement="top-start">
              <span class="close-handle" @click="closeMenu"><i class="el-icon-close"></i></span>
            </el-tooltip>
          </el-col>
        </el-row>
        <div class="content">
          <div class="default-table"
               v-loading="loadingStatus"
               :element-loading-text="loadingText"
               element-loading-spinner="el-icon-loading">
            <el-table
                :data="dataList"
                :span-method="objectSpanMethod"
                @header-contextmenu="headerContextmenu"
                :summary-method="getSummaries"
                show-summary
                style="width: 100%"
                border>
              <el-table-column
                  prop="nickname"
                  label="红人昵称"
                  align="center"
                  min-width="120">
                <template slot-scope="scope">
                  <span>{{ scope.row.nickname }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="platform_name"
                  label="平台"
                  align="center"
                  min-width="100">
              </el-table-column>
              <el-table-column
                  prop="income_type"
                  label="收入分类"
                  align="center"
                  min-width="100">
              </el-table-column>
              <el-table-column
                  prop="average_value"
                  label="上季月营收均值"
                  min-width="120" header-align="center" align="center">
              </el-table-column>
              <el-table-column v-for="(month,index) in quarterlyMonths" :key="index" :label="`${month}月`"
                               align="center">
                <el-table-column
                    :prop="`quotient_${month}`"
                    label="系数"
                    min-width="120" header-align="center" align="center">
                  <editable-cell
                      v-if="userPermissions.indexOf('performance_quotient_edit') !== -1"
                      :show-input="row.editMode"
                      slot-scope="{$index,row,column}"
                      v-model="row[column.property]"
                      @change="editVal(row,column.property,$index)" :is-input-number="false">
                          <span slot="content">
                            <span>{{ row[column.property] }}</span>
                            <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
                          </span>
                  </editable-cell>
                  <template slot-scope="{row,column}" v-else>
                    <span>{{ row[column.property] }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    :prop="`flag_value_${month}`"
                    label="目标"
                    min-width="120" header-align="center" align="center">
                  <editable-cell
                      slot-scope="{$index,row,column}"
                      v-if="isNaN(row[`quotient_${ month }`])&&userPermissions.indexOf('performance_quotient_edit') !== -1"
                      :show-input="row.editMode"
                      v-model="row[column.property]"
                      @change="editVal(row,column.property,$index)" :is-input-number="true">
                          <span slot="content">
                            <span>{{ row[column.property] }}</span>
                            <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
                          </span>
                  </editable-cell>
                  <template slot-scope="{row,column}" v-else>
                    <span>{{ valFormat(row[column.property]) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    :prop="`real_value_${month}`"
                    label="完成"
                    min-width="120" header-align="center" align="center">
                  <template slot-scope="{row,column}">
                    <span>{{ valFormat(row[column.property]) }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column
                  prop="index"
                  label="序号"
                  align="center"
                  width="80" v-if="showIndex">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--    系数修改-->
    <PerformanceQuotientEdit ref="refPerformanceQuotientEdit" @savedEvent="updateQuotientCol"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { numberFormat } from "@/utils/utils";
import EditableCell from "@/components/EditableCell";
import PerformanceQuotientEdit from "@/pages/performance/components/PerformanceQuotientEdit";

export default {
  name: "PerformanceRevenueEdit",
  components: {
    EditableCell, PerformanceQuotientEdit
  },
  props: {
    performanceType: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      showEditIcon: true,
      moveDataelse: {
        x: null,
        y: null
      },
      //start:鼠标右键菜单
      visible: false,
      top: 0,
      left: 0,
      //end:鼠标右键菜单
      currentRow: {},
      currentColumn: {},
      platforms: [],//红人所有平台
      showPlatforms: [],//要显示的平台
      loadingStatus: false,
      showIndex: false,
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      searchCondition: {
        months: []
      },
      dataList: [],
      // tblData: [],//表格数据
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    loadingText() {
      return `【${ this.searchCondition.year }】年【${ this.searchCondition.group_name }】组第【${ this.searchCondition.quarter }】季度的营收绩效目标`
    },
    quarterlyMonths() {
      return this.searchCondition.months
    },
    formQuotientTitle() {
      return `批量修改【${ this.formQuotient.year }年${ this.formQuotient.month }月】【${ this.searchCondition.group_name }】营收绩效系数`
    }
  },
  created() {
  },
  methods: {
    async init(top, left, currentRow, searchCondition) {
      this.currentRow = currentRow
      this.searchCondition = searchCondition
      this.searchCondition.nickname = currentRow.nickname
      this.searchCondition.artist_id = currentRow.artist_id
      this.top = top - 10
      this.left = left
      this.visible = true
      this.initList()
    },
    closeMenu() {
      this.visible = false;
    },
    mouseDownHandle(event) {
      this.$nextTick(() => {
        this.moveDataelse.x = event.pageX - this.$refs.panelRevenue.offsetLeft
        this.moveDataelse.y = event.pageY - this.$refs.panelRevenue.offsetTop
        event.currentTarget.style.cursor = 'move'
        window.onmousemove = this.mouseMoveHandle
      })
    },
    mouseMoveHandle(event) {
      this.$nextTick(() => {
        let moveLeft = event.pageX - this.moveDataelse.x + 'px'
        let moveTop = event.pageY - this.moveDataelse.y + 'px'
        this.$refs.panelRevenue.style.left = moveLeft
        this.$refs.panelRevenue.style.top = moveTop
      })
    },
    mouseUpHandle(event) {
      window.onmousemove = null
      event.currentTarget.style.cursor = 'move'
    },
    async initList() {
      this.loadingStatus = true
      this.dataList = []
      let { list } = await this.$api.getPerformanceRevenueQuarterData(this.searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        this.loadingStatus = false
        this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      })
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              this.spanObj[field][this.positionObj[field]] += 1
              this.spanObj[field].push(0)
            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1) {
        const _row = this.spanObj[column.property][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      if (!isNaN(row[colName])) {
        row[colName] = row[colName] * 1
        //修改系数值-同步计算目标值
        if (colName.indexOf('quotient_') > -1) {
          let arr = colName.split('_')
          let syncField = `flag_value_${ arr[1] }`
          row[syncField] = row[colName] * row['average_value']
        }
        //团队奖金数据同步，重新计算表格数据的时候要使用
        if (colName.indexOf('amount_') > -1) {
          this.bonusRow[colName] = row[colName];
          this.saveBonus(row, colName)
        } else {
          //实时保存数据
          this.save(row, colName)
        }
      }
    },
    //保存
    async save( row, colName ) {
      await this.$api.saveAPerformanceRevenue({
        search_condition: this.searchCondition,
        data: {
          row: row,
          col_name: colName
        }
      })
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus( row, colName ) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: {type: 'revenue', amount: row[colName], month: month}
      })
    },

    headerContextmenu(column, e) {
      // console.log('e', e)
      e.preventDefault();//阻止元素发生默认的行为
      if (column.property.indexOf("quotient_") > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        // this.dialogQuotientVisible = true

        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth; // container width
        const maxLeft = offsetWidth - menuMinWidth; // left boundary
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft; // 15: margin right
        if (left > maxLeft) {
          left = maxLeft;
        }

        // const top = e.clientY
        const top = e.layerY + 100

        this.$nextTick(() => {
          this.$refs['refPerformanceQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },
    updateQuotientCol(formQuotient) {
      //修改系数列
      let quotient = formQuotient.quotient;
      let month = formQuotient.month
      this.dataList.forEach((row) => {
        row[`quotient_${ month }`] = quotient
        row[`flag_value_${ month }`] = quotient * row['average_value']
      })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index < 2) {
          return
        }
        if (index === 2) {
          sums[index] = '合计:'
          return
        }
        // console.log(`${index}）${column.property}`)
        if (column.property.indexOf('average_value') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // // 格式化数据
          switch (column.property) {
            default:
              sums[index] = this.valFormat(sums[index])
              break
          }
        }
      })

      return sums
    }
  }
}
</script>

<style lang="scss" scoped>
.performance-revenue {
  background-color: rgba(255, 255, 255, 0.8);
  border: solid 1px #f2f2f2;
  margin: 0;
  //background: #fff;
  z-index: 1000;
  position: absolute;
  list-style-type: none;
  padding: 15px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    border-top: solid 1px #f2f2f2;
  }

  li {
    margin: 5px 0 2px 0;
    padding: 7px 10px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 49, 118, 0.8);
    }
  }

  .title {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding-inline-start: 12px;
    padding-inline-end: 10px;
    margin-bottom: 20px;
    margin-inline-start: 1px;
    margin-inline-end: 10px;
  }

  .drag-handle {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }

  .close-handle {
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
  }

}
</style>
