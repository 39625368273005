<template>
  <div class="default-table">
    <div style="font-weight: bold;margin-bottom: 5px">红人（KOL）涨粉绩效数据汇总</div>
    <el-table
        :data="summaryData"
        style="width: 100%"
        border>
      <el-table-column
          prop="nickname"
          label="红人昵称"
          align="center"
          min-width="110">
        <template slot-scope="{row}">
          <artist-nickname :info="row" />
        </template>
      </el-table-column>
      <el-table-column
          prop="average_value"
          label="上季月涨粉均值"
          min-width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ valFormat(scope.row[`average_value`]) }}</span>
        </template>
      </el-table-column>
      <el-table-column v-for="(month,index) in quarterlyMonths" :key="index" :label="`${month}月`"
          align="center">
        <el-table-column
            :prop="`flag_value_${month}`"
            label="目标"
            min-width="100" header-align="center" align="center">
          <template slot-scope="{row}">
            <span>{{ valFormat(row[`flag_value_${month}`]) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            :prop="`real_value_${month}`"
            label="完成"
            min-width="100" header-align="center" align="center">
          <template slot-scope="{row}">
            <span>{{ valFormat(row[`real_value_${month}`]) }}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { numberFormat } from "@/utils/utils";

export default {
  name: "TrendKolSummary",
  props: {
    orgData: {
      type: Array,
      default() {
        return []
      }
    },
    quarterlyMonths: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    summaryData() {
      let data = []

      this.orgData.forEach(( item ) => {
        // eslint-disable-next-line no-unused-vars
        let artistId = item.artist_id;
        // console.log(ar)
        let pIndex = data.findIndex(value => value.artist_id === artistId)
        if (pIndex === -1)
          data.push({...item})
        else {
          //上月涨粉均值
          data[pIndex]['average_value'] = data[pIndex]['average_value'] * 1 + item['average_value']
          // data[pIndex]['average_value'] += item['average_value']

          this.quarterlyMonths.forEach(( month ) => {
            this.prefixFields.forEach(( f ) => {
              let field = `${f}${month}`
              data[pIndex][field] = data[pIndex][field] * 1 + item[field] * 1
            })
          })
        }

      })
      return data
    }
  },
  data() {
    return {
      prefixFields: ['flag_value_', 'real_value_'],//'quotient_',
    }
  },
  methods: {
    valFormat( value ) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat( value ) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
  }
}
</script>

<style scoped>

</style>
