<template>
  <el-dialog v-bind="$attrs" v-on="$listeners" @opened="onOpened" @close="onClose" :title="title">
    <div class="default-table">
      <el-table v-loading="loadingStatus" :data="dataList" :highlight-current-row="true" height="600px">
        <el-table-column label="红人昵称" align="center" min-width="100" prop="nickname" />
        <el-table-column label="平台名称" align="center" min-width="100" prop="platform_name" />
        <el-table-column label="是否考核" min-width="60">
          <template slot-scope="scope">
            <el-checkbox true-label="Y" false-label="N" v-model="scope.row.check_status"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" v-if="performanceType==='revenue'">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="updatePerformanceSetting(scope.row,$event)"
            >收入分类
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form label-width="100px">
        <el-form-item style="text-align: center;margin-left:-100px;margin-top:10px;">
          <el-button type="primary" @click="submitFormList()">提交</el-button>
        </el-form-item>
      </el-form>

    </div>
    <!--          平台收入分类设置-->
    <PerformanceSetting :performance-type="`revenue`" ref="refPerformanceSetting" />
  </el-dialog>
</template>

<script>

import PerformanceSetting from "./PerformanceSetting";

export default {
  name: "PlatformSetting",
  inheritAttrs: false,
  components: {
    PerformanceSetting
  },
  props: {
    performanceType: {
      type: String,
      require: true
    },
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      dataList: []
    }
  },
  computed: {
    title() {
      return `${this.performanceType === 'trend' ? '涨粉' : '营收'}绩效-考核配置`
    }
  },
  watch: {},
  created() {
  },
  mounted() {

  },
  methods: {
    async getGroupPerformanceData() {
      this.searchCondition.artist_id = null
      this.searchCondition.nickname = null
      let groupId = this.searchCondition.group_id
      let {list} = await this.$api.getGroupPerformancePlatforms({group_id: groupId, type: this.performanceType})
      this.$nextTick(() => {
        this.dataList = list
      })
    },
    async submitFormList() {
      let info = await this.$api.setGroupPerformancePlatforms({list: this.dataList})
      if (info === 'ok') {
        // this.$notify.success('操作成功')
        this.$emit('savedSetting')
      }
    },
    onOpened() {
      this.$nextTick(() => {
        this.getGroupPerformanceData()
      })

    },
    onClose() {
      this.dataList = []

      if (this.$refs['refPerformanceSetting'])
        this.$refs['refPerformanceSetting'].closeMenu()


    },
    updatePerformanceSetting( row, e ) {
      const menuMinWidth = 105
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      e.preventDefault();//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

      const left = offsetLeft + 200// 15: margin right
      if (left > maxLeft) {
        this.left = maxLeft;
      } else {
        this.left = left;
      }
      const top = e.clientY - offsetTop - 100
      this.$nextTick(() => {
        row.platform_code = row.code
        this.$refs['refPerformanceSetting'].init(top, left, row)
      })
    },
    savedPlatformEvent() {
      //
    },
  }
}

</script>

<style scoped>

</style>
