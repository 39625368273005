<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-col :span="20">
        <el-link type="primary" @click="initList">
          营收绩效目标<span style="color: #1a1a1a">与完成情况</span>
        </el-link>
        <el-link class="op-link" type="primary" @click="editRow({})"
                 v-if="userPermissions.indexOf('performance_quotient_edit') !== -1">
          【{{ groupName }}】
          <i class="el-icon-edit-outline"></i>
        </el-link>
      </el-col>
      <el-col :span="4" style="text-align: right">

        <el-link class="op-link" type="primary" @click="logSetting.visible = true"
                 v-if="userPermissions.indexOf('performance_log') !== -1">
          日志
          <i class="el-icon-document"></i></el-link>
        <el-link class="op-link" type="primary" @click="showSetting"
                 v-if="userPermissions.indexOf('performance_group_setting') !== -1">
          考核设置
          <i class="el-icon-setting"></i></el-link>
      </el-col>
    </el-row>
    <div class="table-view">
      <el-row>
        <el-col :span="24">
          <div class="default-table"
               v-loading="loadingStatus"
               :element-loading-text="loadingText"
               element-loading-spinner="el-icon-loading">
            <el-table
                :data="tblData"
                :span-method="objectSpanMethod"
                @row-contextmenu="rowContextmenu"
                :cell-class-name="cellClassName"
                style="width: 100%"
                border>
              <el-table-column
                  prop="nickname"
                  label="红人昵称"
                  align="center"
                  min-width="100">
                <template slot-scope="{row}">
                  <artist-nickname :info="row"/>
                  <el-tooltip class="item" effect="dark" content="右键菜单，设置红人的考核平台" placement="right"
                              v-if="userPermissions.indexOf('performance_single_setting') > -1">
                    <el-link type="primary"><i class="el-icon-thumb"></i></el-link>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                  prop="platform_name"
                  label="平台"
                  align="center"
                  min-width="90">
                <template slot-scope="{row}">
                  <span>{{ row.platform_name }}</span>
                  <el-tooltip class="item" effect="dark" content="右键菜单，设置考核该平台收入分类" placement="right"
                              v-if="userPermissions.indexOf('performance_single_setting') > -1">
                    <el-link type="primary"><i class="el-icon-thumb"></i></el-link>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                  prop="income_type"
                  label="收入分类"
                  align="center"
                  min-width="90">
              </el-table-column>
              <el-table-column
                  prop="average_value"
                  label="上季月营收均值"
                  min-width="110" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.$index<tblData.length-2">{{ valFormat(scope.row[`average_value`]) }}</span>
                  <span v-else-if="scope.$index===tblData.length-2">{{valFormat(scope.row[`average_value`]) }}
                    <LastQuarterRevenueAverage :search-condition="searchCondition"/>
                  </span>
                  <span v-else>{{ (scope.row[`average_value`]) }}</span>
                </template>
              </el-table-column>
              <el-table-column v-for="(month,index) in quarterlyMonths" :key="index" :label="`${month}月`"
                               align="center">
                <el-table-column
                    :prop="`quotient_${month}`"
                    label="系数"
                    min-width="100" header-align="center" align="center">
                  <template slot-scope="{row,$index}">
                    <span v-if="$index===tblData.length-1" :class="calcBonusClass(row[`amount_${ month }`])">{{
                        showBonus ? moneyFormat(row[`amount_${month}`]) : '/'
                      }}</span>
                    <span v-else>{{ row[`quotient_${month}`] }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    :prop="`flag_value_${month}`"
                    label="目标"
                    min-width="100" header-align="center" align="center">
                  <template slot-scope="{row,$index}">
                    <span v-if="$index===tblData.length-1" :class="calcBonusClass(row[`amount_${ month }`])">{{
                        showBonus ? moneyFormat(row[`amount_${month}`]) : '/'
                      }}</span>
                    <span v-else>{{ valFormat(row[`flag_value_${month}`]) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                    :prop="`real_value_${month}`"
                    label="完成"
                    min-width="100" header-align="center" align="center">
                  <template slot-scope="{row,$index}">
                    <span v-if="$index===tblData.length-1" :class="calcBonusClass(row[`amount_${ month }`])">{{
                        showBonus ? moneyFormat(row[`amount_${month}`]) : '/'
                      }}</span>
                    <span v-else>{{ valFormat(row[`real_value_${month}`]) }}</span>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>

        </el-col>
      </el-row>
      <el-row>
        <el-col :span="14" style="text-align: right">
          <div>
            <span class="title-check" :title="`若要修改核算完成数据，需要【权限-核算完成】`">核算完成：</span>
            <el-checkbox-group v-model="checkMonthList" style="display: inline!important;">
              <el-checkbox :label="month" :name="`month_${month}`" v-for="(month,index) in quarterlyMonths"
                           :key="index"
                           :disabled="userPermissions.indexOf('performance_check')===-1"
                           @change="changeCheckMonth(month)">{{
                  month
                }}月份
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :span="10" style="text-align: right;margin-top: 10px">
          <el-button type="primary" @click="saveConfirm" :disabled="commitLoading"
                     v-if="userPermissions.indexOf('performance_save')>-1">
            {{ commitLoading ? '提交中...' : '确认发布' }}
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <RevenueKolSummary :org-data="dataList" :quarterly-months="quarterlyMonths"/>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span='24'>
          <performance-suggestion-panel :search-condition='searchCondition' v-if='userPermissions.indexOf("performance_suggestion_show")>-1'/>
        </el-col>

      </el-row>
      <el-row>
        <el-col :span="24">
          <div v-if="userPermissions.indexOf('performance_history_show')>-1">
            <el-divider content-position="left">历史营收绩效</el-divider>
            <RevenueMap/>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12" style="text-align: left">
          <Tips :data="tipsList"/>
        </el-col>
        <el-col :span="12">
          <ol>
            <li class="tips-title">运营组月度绩效奖金包金额，算法为：
              <p class="tips-item">1.1、上季度组平均月营收在0-30万（含30万整），本季度月奖金包核算为20000元；</p>
              <p class="tips-item">1.2、上季度组平均月营收在30-50万（含50万整），本季度月奖金包核算为30000元；</p>
              <p class="tips-item">1.3、上季度组平均月营收在50-100万（含100万整），本季度月奖金包核算为50000元；</p>
              <p class="tips-item">1.4、上季度组平均月营收在100万以上，本季度月奖金包核算为60000元。</p>
              <p class="tips-item">1.5、单月营收不足10万元，奖金包为0元。</p>
            </li>
            <li class="tips-title">营收绩效奖金的计算比例，算法为：
              <p class="tips-item">2.1、上季度组平均月营收在0-30万（含30万整），本季度营收奖金算法为超出部分20%；</p>
              <p class="tips-item">2.2、上季度组平均月营收在30-50万（含50万整），本季度营收奖金算法为超出部分20%；</p>
              <p class="tips-item">2.3、上季度组平均月营收在50-100万（含100万整），本季度营收奖金算法为超出部分15%；</p>
              <p class="tips-item">2.4、上季度组平均月营收在100万以上，本季度营收奖金算法为超出部分10%。</p>
            </li>
          </ol>
        </el-col>
      </el-row>
    </div>
    <br/>
    <!--          红人平台设置-->
    <PerformancePlatformsSelect :performance-type="`revenue`" ref="refPerformancePlatformsSelect"
                                @savedEvent="savedPlatformEvent"/>
    <!--          平台收入分类设置-->
    <PerformanceSetting :performance-type="`revenue`" ref="refPerformanceSetting"
                        @savedEvent="savedPlatformEvent"/>
    <!--    系数修改-->
    <PerformanceQuotientEdit ref="refPerformanceQuotientEdit" @savedEvent="updateQuotientCol"/>
    <!--    营收数据修改-->
    <ApeDrawer :drawerData="drawerData" @drawerClose="drawerClose">
      <template slot="ape-drawer">
        <PerformanceRevenuePanel ref="refPerformanceRevenuePanel"/>
      </template>
    </ApeDrawer>
    <!--    拖拽框：营收数据-->
    <PerformanceRevenueEdit ref="refPerformanceRevenueEdit"/>
    <!--    日志-->
    <RevenueLog
        :visible="logSetting.visible"
        @close="closeLog"
        :search-condition="searchCondition"
        v-if="userPermissions.indexOf('performance_log') !== -1"/>

    <!--   整组绩效考核设置-->
    <PlatformSetting
        :visible="performanceSetting.visible"
        @close="closeSetting"
        @savedSetting="closeSetting"
        :search-condition="searchCondition"
        :performance-type="`revenue`"
        v-if="userPermissions.indexOf('performance_group_setting') !== -1"/>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import { numberFormat } from '@/utils/utils'
import { mapGetters } from 'vuex'
import PerformancePlatformsSelect from '@/pages/performance/components/PerformancePlatformsSelect'
import PerformanceRevenueEdit from '@/pages/performance/components/PerformanceRevenueEdit'
import PerformanceQuotientEdit from '@/pages/performance/components/PerformanceQuotientEdit'
import ApeDrawer from '../../../components/ApeDrawer'
import PerformanceRevenuePanel from './PerformanceRevenuePanel'
import RevenueLog from '@/pages/performance/components/RevenueLog'
import PerformanceSetting from '@/pages/performance/components/PerformanceSetting'
import PlatformSetting from './PlatformSetting'
import Tips from '@/components/Tips'
import RevenueKolSummary from '@/pages/performance/components/RevenueKolSummary'
import RevenueMap from '@/pages/performance/RevenueMap'
import LastQuarterRevenueAverage from '@/pages/performance/components/LastQuarterRevenueAverage'
import PerformanceSuggestionPanel from '@/pages/performance/components/PerformanceSuggestionPanel'

export default {
  name: 'PerformanceRevenue',
  components: {
    PerformanceSuggestionPanel,
    LastQuarterRevenueAverage,
    RevenueMap,
    RevenueKolSummary,
    EditableCell,
    PerformancePlatformsSelect,
    PerformanceSetting,
    PerformanceRevenueEdit,
    PerformanceQuotientEdit,
    ApeDrawer,
    PerformanceRevenuePanel,
    RevenueLog,
    PlatformSetting, Tips
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {};
      }
    },
    showBonus: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      tipsList: [
        '目标值 = 上季月均值 * 系数（若系数为“/”，则可手动输入目标值，前提：有录入权限）',
        '上季涨粉均值：求上季度三个月的新增粉丝数/3，并四舍五入取整',
        '数据录入：数据录入后，实时保存，但是其他人不可见，需要发布后才能同步给其他人',
        '数据确认：1)【确认发布】后数据，其他人即可看到最新数据 2)【确认发布】后若继续修改数据，需要重新点击发布其人才能看到最新数据',
        '设置考核：1) 单个设置，右键“红人昵称/平台”，可进行配置；2) 整组考核设置，点击右上角“考核设置”',
        '批量修改系数：在编辑页面，右键列“系数”，可进行修改',
        '数据版本：已发布/编辑中（有系数录入权限的人才能看[编辑中]的数据）',
        '自营电商-完成值： 电商数据-店铺数据的月度总支付金额 * 0.03（系数），即：月总支付金额：10000，则完成值： 300（10000*0.03）。',
      ],
      showIndex: false,//显示序号
      loadingStatus: false,
      showEditIcon: true,
      commitLoading: false,
      edit_mode: 'add',
      // searchCondition: {
      //   months: []
      // },
      bonus_items: [],//团队奖金
      dataList: [],
      mergeFields: ['nickname', 'platform_name'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      fixFields: ['group_name', 'platform_name', 'income_type', 'average_value'],
      prefixFields: ['quotient_', 'flag_value_', 'real_value_'],
      calcFields: [],
      bonusRow: {nickname: '团队奖金', platform_name: '团队奖金', income_type: '团队奖金', average_value: '团队奖金'},//团队奖金行的可编辑列
      currentRow: {},
      currentColumn: {},
      dialogQuotientVisible: false,
      formQuotient: {
        quotient: 0,
        month: 0,
        year: 0,
        group_id: ''
      },
      mode: 'drawer',
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '90%',
        show_footer: false,
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      logSetting: {
        visible: false,
      }, performanceSetting: {
        visible: false,
      },
      checkMonthList: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    groupName() {
      return this.searchCondition.group_name
    },
    loadingText() {
      return `【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的营收绩效目标数据加载中...`
    },
    quarterlyMonths() {
      return this.searchCondition.months
    },
    tblData() {
      if (this.dataList.length > 0) {
        let calcRow = this.calcCol()

        //合计数据-初始化
        let appendRows = [{nickname: '本组总营收', platform_name: '本组总营收', income_type: '本组总营收', ...calcRow}]
        appendRows.push({...this.bonusRow})

        return [...this.dataList, ...appendRows]
      }

      return []
    },
    // formQuotientTitle() {
    //   return `批量修改【${ this.formQuotient.year }年${ this.formQuotient.month }月】【${ this.searchCondition.group_name }】营收绩效系数`
    // }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('searchCondition',val)
        this.initList()
      }
    }
  },
  methods: {
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    // eslint-disable-next-line
    editVal(row, colName, rowIndex) {
      // console.log(`当前操作第${rowIndex},列${colName},isNaN:${isNaN(row[colName])}`)
      if (!isNaN(row[colName])) {
        row[colName] = row[colName] * 1
        //修改系数值-同步计算目标值
        if (colName.indexOf('quotient_') > -1) {
          let arr = colName.split('_')
          let syncField = `flag_value_${arr[1]}`
          row[syncField] = row[colName] * row['average_value']
        }
        //团队奖金数据同步，重新计算表格数据的时候要使用
        if (colName.indexOf('amount_') > -1) {
          this.bonusRow[colName] = row[colName];
          this.saveBonus(row, colName)
        } else {
          //实时保存数据
          this.save(row, colName)
        }
      }

    },
    //保存
    async save(row, colName) {
      await this.$api.saveAPerformanceRevenue({
        search_condition: this.searchCondition,
        data: {
          row: row,
          col_name: colName
        }
      })
    },
    /**
     * 保存-团队奖金
     * @param row
     * @param colName
     * @returns {Promise<void>}
     */
    async saveBonus(row, colName) {
      let arr = colName.split('_')
      let month = arr[1]
      await this.$api.saveBonus({
        search_condition: this.searchCondition,
        data: {type: 'revenue', amount: row[colName], month: month}
      })
    },
    init(condition) {
      this.searchCondition = condition
      this.$nextTick(() => {
        if (this.$refs['refPerformancePlatformsSelect'])
          this.$refs['refPerformancePlatformsSelect'].closeMenu()

        if (this.$refs['refPerformanceSetting'])
          this.$refs['refPerformanceSetting'].closeMenu()
      })

      this.initList()
    },
    async initList() {
      this.$nextTick(() => {
        if (this.$refs['refPerformancePlatformsSelect'])
          this.$refs['refPerformancePlatformsSelect'].closeMenu()

        if (this.$refs['refPerformanceSetting'])
          this.$refs['refPerformanceSetting'].closeMenu()
      })

      this.getCalcFields()
      //当前已核算完成的月份
      await this.getCheckMonths()
      this.loadingStatus = true
      this.dataList = []
      let {list, edit_mode, bonus_items} = await this.$api.getPerformanceRevenueQuarterData(this.searchCondition)
      this.edit_mode = edit_mode
      this.bonus_items = bonus_items
      if (this.bonus_items.length > 0) {
        let row = {};
        this.bonus_items.forEach((item) => {
          row[`amount_${item.month}`] = item.amount;
        })
        this.bonusRow = {nickname: '团队奖金', platform_name: '团队奖金', income_type: '团队奖金', average_value: '团队奖金', ...row}
      }

      this.$nextTick(() => {
        this.dataList = list
        this.loadingStatus = false

        this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      })
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.dataList[index][field] === this.dataList[index - 1][field]) {
              if (field === 'platform_name') {
                //平台列，特殊处理
                if (this.dataList[index]['nickname'] === this.dataList[index - 1]['nickname']) {
                  this.spanObj[field][this.positionObj[field]] += 1
                  this.spanObj[field].push(0)
                } else {
                  this.spanObj[field].push(1)
                  this.positionObj[field] = index
                }
              } else {
                this.spanObj[field][this.positionObj[field]] += 1
                this.spanObj[field].push(0)
              }

            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },
    // 表格合并行

    // eslint-disable-next-line
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      //末尾查询一行合计：
      if (rowIndex === this.tblData.length - 1) {
        // 3个单元格合并成一个单元格:0、1 、2;3、4、5;6、7、8;
        if (columnIndex < 4) {
          if ((columnIndex + 1) % 4 === 0) {
            return [1, 4];
          } else {
            return [0, 0];
          }
        } else if ((columnIndex + 1) % 3 === 0) {
          return [1, 3];
        } else {
          return [0, 0];
        }
      } else if (rowIndex === this.tblData.length - 2) {
        // 本组总涨粉：
        if (columnIndex < 2) {
          return [0, 0];
        } else if (columnIndex === 2) {
          return [1, 3];
        } else {
          return [1, 1];
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
    },
    getCalcFields() {
      this.calcFields = []
      this.calcFields.push('average_value')
      if (this.quarterlyMonths && this.quarterlyMonths.length > 0) {
        this.quarterlyMonths.forEach((month) => {
          this.bonusRow[`amount_${month}`] = 0//团队奖金行数据初始化

          this.prefixFields.forEach((prefixField) => {
            let field = `${prefixField}${month}`
            this.calcFields.push(field)
          })
        })
      }
    },
    calcCol() {
      let calcRow = {}
      let len = this.dataList.length //10
      if (len > 0) {
        //0~9
        this.dataList.forEach((item) => {
          if (this.calcFields && this.calcFields.length > 0) {
            this.calcFields.forEach((field) => {
              if (field.indexOf('quotient_') === -1) {

                if (!calcRow[field]) {
                  calcRow[field] = 0
                }
                let val = item[field] || 0

                //若考核，才累计（need_check_*）
                let arr = field.split('_')
                let month = parseInt(arr[arr.length - 1])//数据类型：整型
                let needCheckField = `need_check_${month}`
                if (item[needCheckField] === 'N') {
                  return false
                }
                calcRow[field] += val * 1;
              }
            })
          }
        })
      }
      return calcRow
    },
    //确认操作
    saveConfirm() {
      let msg = `确认发布【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的营收绩效目标？发布后其他人将能看到最新的数据, 是否继续?`;

      // let msg = `确认保存【${this.searchCondition.year}】年【${this.searchCondition.group_name}】组第【${this.searchCondition.quarter}】季度的涨粉绩效目标信息, 是否继续?`;
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.publishSaved()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消'
        // });
      });

    },
    //发布已保存到数据
    async publishSaved() {
      this.commitLoading = true
      let info = await this.$api.publishPerformanceRevenue({
        search_condition: this.searchCondition
      })
      this.commitLoading = false
      if (info === 'ok') {
        this.$notify.success('发布成功')
        this.editStatus = false
        await this.initList()
      }
    },
    closeAllMenu() {
      if (this.$refs['refPerformancePlatformsSelect'])
        this.$refs['refPerformancePlatformsSelect'].closeMenu()

      if (this.$refs['refPerformanceSetting'])
        this.$refs['refPerformanceSetting'].closeMenu()
    },
    async rowContextmenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      // console.log(`红人：${row.nickname},平台：${row.platform_name},列：${column.property}`)
      if (this.userPermissions.indexOf('performance_single_setting') > -1) {
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth; // container width
        const maxLeft = offsetWidth - menuMinWidth; // left boundary
        e.preventDefault();//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        const left = e.clientX - offsetLeft; // 15: margin right
        if (left > maxLeft) {
          this.left = maxLeft;
        } else {
          this.left = left;
        }
        const top = e.clientY - offsetTop

        this.closeAllMenu()

        if (['nickname'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformancePlatformsSelect'].init(top, left, row)
          })
        } else if (['platform_name'].indexOf(column.property) > -1) {
          this.$nextTick(() => {
            this.$refs['refPerformanceSetting'].init(top, left, row)
          })
        }
        //refPerformanceSetting

      }
    },
    savedPlatformEvent() {
      //修改完红人考核平台
      this.$confirm('红人考核平台已调整，是否刷新数据?', '提示', {
        confirmButtonText: '刷新',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(() => {
        this.initList()
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    headerContextmenu(column, e) {
      e.preventDefault();//阻止元素发生默认的行为
      if (column.property.indexOf("quotient_") > -1) {
        let arr = column.property.split('_')
        // let month = arr[1]
        this.formQuotient.month = arr[1]
        this.formQuotient.year = this.searchCondition.year
        this.formQuotient.group_id = this.searchCondition.group_id
        // this.dialogQuotientVisible = true

        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth; // container width
        const maxLeft = offsetWidth - menuMinWidth; // left boundary
        e.preventDefault();//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft; // 15: margin right
        if (left > maxLeft) {
          left = maxLeft;
        }
        // const top = e.clientY
        const top = e.layerY + 100

        this.$nextTick(() => {
          this.$refs['refPerformanceQuotientEdit'].init(top, left, this.formQuotient)
        })
      }
    },
    updateQuotientCol(formQuotient) {
      let quotient = formQuotient.quotient;
      let month = formQuotient.month
      this.dataList.forEach((row) => {
        let colName = `quotient_${month}`
        row[colName] = quotient
        row[`flag_value_${month}`] = quotient * row['average_value']
        this.save(row, colName)
      })
    },
    editRow(row) {
      this.drawerData.loading_text = '加载中……'
      this.drawerData.title = `${this.searchCondition.year}年第${this.searchCondition.quarter}季度【${this.searchCondition.group_name}】营收数据-录入`
      this.drawerData.visible = true
      this.drawerData.loading = false
      this.$nextTick(() => {
        this.$refs['refPerformanceRevenuePanel'].init(row, {...this.searchCondition})
      })

    },
    editTable(row, e) {
      if (this.mode === 'drawer') {
        this.editRow(row)
      } else {
        // this.currentRow = row
        const menuMinWidth = 105
        const offsetWidth = this.$el.offsetWidth; // container width
        const maxLeft = offsetWidth - menuMinWidth; // left boundary
        e.preventDefault();//阻止元素发生默认的行为
        const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
        // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left

        let left = e.clientX - offsetLeft; // 15: margin right
        if (left > maxLeft) {
          left = maxLeft;
        } else {
          left = left + 100;
        }
        const top = 20
        this.$nextTick(() => {
          this.$refs['refPerformanceRevenueEdit'].init(top, left, row, {...this.searchCondition})
        })
      }


    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
      this.initList()
    },
    showLog() {
      this.logSetting.visible = true
    },
    closeLog() {
      this.logSetting.visible = false
    },
    showSetting() {
      this.performanceSetting.visible = true
    },
    closeSetting() {
      this.performanceSetting.visible = false
      this.initList()
    },
    jumpToDetail(row) {
      const keyword = 'DingTalk'
      if (navigator.userAgent.indexOf(keyword) > -1) {
        // 获取订单配置
        this.$router.push({
          name: 'artist_trend_chart',
          params: {artist_id: row.artist_id},
          query: {group_name: row.group_name}
        })
      } else {
        const {href} = this.$router.resolve({
          name: 'artist_trend_chart',
          params: {artist_id: row.artist_id},
          query: {group_name: row.group_name}
        })
        window.open(href, '_blank')
      }
    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({row, column, rowIndex, columnIndex}) {
      //月考核：
      let unCheckBool = false
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_');
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        let fieldNeedCheck = `need_check_${month}`
        if (row[fieldNeedCheck] === 'N') {
          unCheckBool = true
          // console.log('fieldNeedCheck', row[fieldNeedCheck])
        }
      }
      if (unCheckBool) {
        return 'performance-uncheck'
      }

      //核算完成：
      let checkBool = false
      let months = this.checkMonthList
      if (column.property.indexOf('quotient_') > -1 || column.property.indexOf('flag_value_') > -1 || column.property.indexOf('real_value_') > -1) {
        let arr = column.property.split('_');
        let month = parseInt(arr[arr.length - 1])//数据类型：整型
        if (months.indexOf(month) > -1) {
          checkBool = true
        }
      }

      // console.log(`col:${ column.property },checkBool:${ checkBool }`)
      if (checkBool) {
        return 'performance-checked'
      } else
        return ''

    },
    async changeCheckMonth(month) {

      let params = {
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        month: month,
        check_status: this.checkMonthList.indexOf(month) > -1 ? 'Y' : 'N'
      }
      let info = await this.$api.setPerformanceCheckedMonth(params)
      if (info === 'ok') {
        this.$notify.success('操作成功')
      }
    },
    async getCheckMonths() {
      let {months} = await this.$api.getPerformanceCheckedMonths({
        type: 'revenue',
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        months: this.quarterlyMonths,
      })
      //当前已核算完成的月份
      this.checkMonthList = [...months]
    },
    calcBonusClass(val) {
      if (val > 0) {
        return 'bonus-gt0'
      } else
        return ''
    },
    showQuarterReal(){

    }
  }
}
</script>

<style scoped>
.real-agv{
  font-size: 0.8em;
  font-weight: bold;
  color: #ff0000;
}

.tips li {
  /*font-weight: lighter;*/
  font-size: 8px;
  /*font-style: italic;*/
  color: #909399;
  margin-bottom: 5px;
}

.table-view {
  max-height: 80vh;
  padding-bottom: 10px;
  padding-left: 5px;
}

.amount {
  border: #00feff solid 1px;
  width: 200px;
  line-height: 20px;

}

.edit-btn {
  margin-left: 10px;
}

.op-link {
  margin-left: 10px;
}

.tips-title {
  /*font-weight: lighter;*/
  font-weight: 800;
  font-size: 14px;
  /*font-style: italic;*/
  /*color: #909399;*/
  margin-bottom: 5px;
  margin-top: 15px;
}

.tips-item {
  /*font-weight: lighter;*/
  font-size: 12px;
  /*font-style: italic;*/
  color: #909399;
  margin-bottom: 5px;
}
</style>
