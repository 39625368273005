<template>
  <div>
    <el-card>
      <div slot='header' class='clearfix'>
        <el-link @click='getInfo'>绩效反馈和建议
          【{{ `${searchCondition.group_name} (${searchCondition.year}年${searchCondition.quarter}季度)` }}】
        </el-link>
        <div v-if='userPermissions.indexOf("performance_suggestion_save")>-1'>
          <el-button style='float: right; padding: 3px 0' type='text' @click='mode="edit"' v-if='mode==="saved"'><i
            class='el-icon-edit-outline'></i>修改
          </el-button>
          <el-button style='float: right; padding: 3px 0' type='text' @click='saveSuggestion' v-else>保存</el-button>
        </div>

      </div>
      <div>
        <el-form>
          <el-form-item>
            <el-input type='textarea' v-model='formSuggestion.suggestion' :autosize='autosize' show-word-limit
                      maxlength='500' placeholder='请输入本季度绩效的反馈与建议' :readonly="mode=='saved'"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PerformanceSuggestionPanel',
  props: {
    searchCondition: {
      type: Object,
      default() {
        return { year: null, quarter: null, group_id: null }
      }
    }
  },
  watch: {
    searchCondition: {
      immediate: true,
      deep: true,
      handler() {
        this.getInfo()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      //绩效反馈与建议
      formSuggestion: {
        id: null,
        year: null,
        quarter: null,
        group_id: null,
        suggestion: 'hello world'
      },
      autosize: { minRows: 10, maxRows: 15 },
      mode: 'saved'
    }
  },
  methods: {
    async saveSuggestion() {
      this.formSuggestion.group_id = this.searchCondition.group_id
      this.formSuggestion.group_name = this.searchCondition.group_name
      this.formSuggestion.year = this.searchCondition.year
      this.formSuggestion.quarter = this.searchCondition.quarter
      this.mode = 'saved'
      let id = await this.$api.savePerformanceSuggestion(this.formSuggestion)
      if (id) {
        this.$notify.success('反馈建议【保存成功】')
        this.mode = 'saved'
      } else {
        this.mode = 'edit'
      }
    },
    async getInfo() {
      let { info } = await this.$api.getPerformanceSuggestion({
        group_id: this.searchCondition.group_id,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter
      })
      this.formSuggestion.id = info.id || null
      this.formSuggestion.suggestion = info.suggestion || null
    }
  }
}
</script>

<style scoped>

</style>